<template>
	<modal class="NoxModalUserCard" name="NoxModalUserCard" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'showUserCard'" v-html="$store.getters.getLanguageText('5.19', 0, { id: noxId })"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxType == 'showUserCard'">
							<div class="nox_modal_info_user_card" v-if="Object.keys(noxUserCardData).length">
								<div class="nox_modal_info_user_card_avatar">
									<div class="nox_modal_info_user_card_avatar_img"><img :src="$parent.$parent.$parent.getAvatarByLink(noxUserCardData.avatar)"></div>
									<div class="nox_modal_info_user_card_avatar_rating" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 150), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }"><div><span :style="{ width: (noxUserCardData.rating * 100 / $store.state.noxSystemSettings.max_rating) + '%' }"></span></div></div>
									<div class="nox_modal_info_user_card_avatar_status" :style="{ background: '#' + $store.state.noxSystemSettings['user_status_color_' + noxUserCardData.status] }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 117, { status: $store.getters.getLanguageText('4.3.1', noxUserCardData.status), count: $store.state.noxSystemSettings['user_status_cycles_' + noxUserCardData.status] }), trigger: $store.state.noxIsMobile ? 'click' : 'click hover' }" v-if="noxUserCardData.status">{{ $store.state.noxSystemSettings['user_status_name_' + noxUserCardData.status] }}</div>
								</div>
								<div class="nox_modal_info_user_card_content">
									<div class="nox_modal_info_user_card_content_row">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 1)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ noxUserCardData.user_id }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 12)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ noxUserCardData.sponsor_id }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 2)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ $store.state.noxSystemSettings['package_name_x' + noxUserCardData.package] }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row" v-if="noxUserCardData.username">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 3)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ noxUserCardData.username }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 9)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ new Date(noxUserCardData.created_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row" v-if="$store.state.noxAccountData.group == 1">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 10)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ new Date(noxUserCardData.package_buy_at * 1000).toLocaleString($store.state.noxLanguage) }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row" v-if="noxUserCardData.status">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 4)"></div>
										<div class="nox_modal_info_user_card_content_value"><a href="https://forum.nox.bz/topic/204-statusy-v-klientskoy-programme-nox/" target="_blank" v-html="$store.getters.getLanguageText('4.3.1', noxUserCardData.status)"></a></div>
									</div>
									<div class="nox_modal_info_user_card_content_row">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 5)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ noxUserCardData.count_of_invited_users }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 11)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ (noxUserCardData.count_of_completed_quests > $store.state.noxSystemSettings.count_of_available_quests ? $store.state.noxSystemSettings.count_of_available_quests : noxUserCardData.count_of_completed_quests) }} / {{ $store.state.noxSystemSettings.count_of_available_quests }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row" v-if="$store.state.noxAccountData.group == 1">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 6)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ noxUserCardData.count_start_bots }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row" v-if="$store.state.noxAccountData.group == 1">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 7)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ noxUserCardData.count_online_bots }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_row" v-if="$store.state.noxAccountData.id <= noxId">
										<div class="nox_modal_info_user_card_content_label" v-html="$store.getters.getLanguageText('5.19', 8)"></div>
										<div class="nox_modal_info_user_card_content_value">{{ (Number(noxUserCardData.lcount) + Number(noxUserCardData.rcount)) }}</div>
									</div>
									<div class="nox_modal_info_user_card_content_socs" v-if="noxUserCardData.vk || noxUserCardData.instagram || noxUserCardData.facebook || noxUserCardData.whatsapp || noxUserCardData.telegram || noxUserCardData.skype">
										<a :href="noxUserCardData.vk" target="_blank" v-if="noxUserCardData.vk"><font-awesome-icon :icon="['fab', 'vk']" /></a>
										<a :href="noxUserCardData.instagram" target="_blank" v-if="noxUserCardData.instagram"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
										<a :href="noxUserCardData.facebook" target="_blank" v-if="noxUserCardData.facebook"><font-awesome-icon :icon="['fab', 'facebook']" /></a>
										<a :href="'https://api.whatsapp.com/send?phone=' + noxUserCardData.whatsapp" target="_blank" v-if="noxUserCardData.whatsapp"><font-awesome-icon :icon="['fab', 'whatsapp']" /></a>
										<a :href="'https://t.me/' + noxUserCardData.telegram.replace(/@/gi, '')" target="_blank" v-if="noxUserCardData.telegram"><font-awesome-icon :icon="['fab', 'telegram']" /></a>
										<a :href="'skype:' + noxUserCardData.skype + '?chat'" target="_blank" v-if="noxUserCardData.skype"><font-awesome-icon :icon="['fab', 'skype']" /></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxId: 0,
			noxType: '',
			noxUserCardData: {},
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxUserCardData = {};
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				this.axios();
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 2); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'showUserCard') {
						_this.noxIsOpenLoading = true;
						config.url = '/v2' + (_this.$store.state.noxAccountData.group == 1 ? '/admin' : '') + '/account/users/card';
						config.params = { user_id: _this.noxId };
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'showUserCard') {
									_this.noxUserCardData = data.data;
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'showUserCard') {
										if      (data.response.data.error == 'USER_ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'USER_ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'showUserCard') {
										if (data.response.data.error == 'USER_ID_NOT_FOUND') { _this.noxAlert = _this.getError(3); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalUserCard');
			}
		}
	}
</script>
